.footerBg-Img {
  background-color: #0a240f;
}

.companyDetail-Footer {
  color: #bcc2bf !important;
  font-size: .9em !important;
  margin-top: 2em !important;

}



.social-Links a {
  color: #c6c6c6 !important;
  margin: 0 1vh !important;
  width: 5em !important;

}
.social-Links a svg {
  font-size: 25px;
  margin: 10px;

}

.social-Links a:hover {
  color: #f0f0f0 !important;
}

@media (max-width:767.98px) {
  .social-Links {
    display: flex;
    justify-content: center;
  }
}

.heading-Footer {

  color: white !important;
  font: 600 1.2em  !important;
}

.footerhr-Line {
  width: 25px !important;
  color: white !important;
  margin-top: 0 !important;

  height: 3px !important;
  opacity: 1;
}

.footerLinks {
  color: #cdcdcd !important;
  font-size: .9em !important;
  margin-top: 2em !important;
  text-decoration: none !important;
  line-height: 3em !important;

}

.footerLinks:hover {

  color: #ffffff !important;
}

ul li {
  list-style: none !important;
}
.logotext2{
  font-size: 18px !important;
}