.carousel-control-prev-icon  {
  border: 1px solid rgba(0, 0, 0, 0.904);
  background-color: rgba(0, 0, 0, 0.904);
  margin-right: 130px !important;
  width: 25px !important;
 
}
.carousel-control-next-icon{
  border: 1px solid rgba(0, 0, 0, 0.904);
  background-color: rgba(0, 0, 0, 0.904);
  margin-left: 130px !important;
  width: 25px !important;
  
}




