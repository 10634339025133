.getintouch-padding{
  padding: 5.5em 0  !important;
  
}
.getintouch-heading{

  font: bold 1.5em  'Alegreya'  !important;
  text-transform: uppercase;
  color: black !important;
  
}
.getintouch-hrline{
  width: 3em !important;
  height: 2px !important;
  color: #0f3a18 !important;
  opacity: 1 !important;
  margin-top: 0 !important;
  margin-bottom: 3em !important;
  
}
.form-border{
  border: 9px solid #1e8133 !important;
  padding: 1em 1.5em !important; 
  overflow: hidden;
  margin-bottom: 1.5em !important;
}
.form-border input, textarea{
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #1e8133 !important;
  outline: none  
 }
/* 
.username{
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #bc7c7c !important;
  outline: none  ;
}
.yourEmail{
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #bc7c7c !important;
  outline: none  ;
}
.yourPhoneNumber{
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #bc7c7c !important;
  outline: none  ;
}
.yourSubject{
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #bc7c7c !important;
  outline: none  ;
}
.textArea{
  
  margin-bottom: 20px !important;
  font: normal 0.8em 'Open Sans';
  padding: 11px 20px !important;
  border: 1px solid #bc7c7c !important;
  outline: none  ;
} */

.getInTouch-Btn{
   color: white;
   text-decoration: none;
  
  font: bold 1em/1em 'Roboto' !important;
  
  border-radius: 4px !important;
  padding: 1em 1.5em;
  display: block;
  background: linear-gradient(to left,#0f3a18 50%,#009bb3 50%);
  transition:all .5s ease-out;
  background-size: 201% 100%;
  background-position: right ; 
  

 
}

.getInTouch-Btn:hover{
background-position: left;
}
.getInTounch-SideImg{
  /* margin-bottom: 1.5em !important;
   */
   width: 100%;
   height: 33.5em;
}

@media (min-width:100px){
  .getInTounch-SideImg{
    display: block;
  }
}