.P-header {
    background-color: #0f3a18dd;
    width: 100%;
    height: 220px;
}


.heading-title {

    font: bold 2em/1em "Alegreya", serif;
    color: #00076e;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 10px;
    letter-spacing: .3px;
    margin: 0 0 1em;
}

.line-box {
    position: relative;
}

.heading-title::before {
    content: "";
    width: 10%;
    height: 3px;
    display: inline-block;
    background: #0f3a18dd;
    position: absolute;
    bottom: 0;
}