*{
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
.topnav-text{
  font-size: 16px;
  color: #ffffff;
  font-weight:bolder ;
}
@media only screen and (min-width:1100px) and (max-width:1300px) {
  li .nav-link  {
   font-size: 15px !important;
  }
  .dropbtnss {
    font-size: 15px !important;
   
  }
}