.PD-header {
    background-color: #0f3a18dd;
    width: 100%;
    height: 220px;
  }
  
.CP-Heading{
    margin: 4em 0px 0px 0px;
    padding: 10px;
}

.CP-main{
    margin: 25px;
    padding: 10px;
}
.hoverCards{
    transition: 0.5s;
}
.hoverCards:hover{
    background-color: #0f3a18dd;
    color: white;
    border-radius: 4px;
}
.card-align{
    display: inline-flex;
    justify-content: center;
    align-items: stretch;
}