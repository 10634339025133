.copyRight-bgcolor{
  background-color: #161616 !important;
  
}
.footer-lasttext{
  color: #d0d0d0 !important;
  font-size: .8em !important;
}
@media (max-width:767.98px){
  .footer-lasttext{
    display: flex !important;
    justify-content: center !important;
    
  }
}


/* #9DB624 */