.buttons-container button {
    /* background: white; */
    border: solid 2px#ffffff;
    padding: .375em 1.125em;
    font-size: 1.2rem;
  }
  
  .button-arounder {
    /* font-size: 25px; */
    /* background: hsl(115, 30%, 15%); */
    background: #0f3a18;
    color: hsl(190deg, 10%, 95%);
    font-weight: 600;
    box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
    transform: translateY(0);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    
    --dur: .15s;
    --delay: .15s;
    --radius: 16px;
    
    transition:
      border-top-left-radius var(--dur) var(--delay) ease-out,
      border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
      border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
      border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
      box-shadow calc(var(--dur) * 4) ease-out,
      transform calc(var(--dur) * 4) ease-out,
      background calc(var(--dur) * 4) steps(4, jump-end);
  }
  .button-arounder a {
    text-decoration: none;
    color: aliceblue;
  }
  .button-arounder:hover,
  .button-arounder:focus {
    box-shadow: 0 4px 8px hsla(195, 15%, 5%, 0.791);
    transform: translateY(-4px);
    background: #00076e;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }