
.card{
  margin: 15px 0px;
}
.main-para{
  margin: 15px 0px;
  padding: 15px 0px;
}
.card-image {
    display: flex;
    justify-content: center;
    margin: 15px;}
.card-passage {
    padding: 10px 30px 10px 30px;
}

/* .card-btn {

    background: linear-gradient(to left, #0f3a18 50%, #1e8133  50%);
    background-size: 200% 100%;
    font-weight: 500;
    background-position: right;
    color: white;
    font-size: medium;
    border-radius: 4px;
    border: transparent;
    transition: all 1s ease-out;
    text-decoration: none;
    padding: 15px 15px !important;
  }
  
  .card-btn:hover {
    background-position: left;
    color: white !important;
    border: 1px solid white;
  } */