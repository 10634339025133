.contactus-bgcolor{
  background-color:#0f3a18 !important;
  color: White !important;
}
.contactus-text{
  font-size: 1.4em !important;
  font-weight: 500 !important;
  font-family: 'Open Sans', sans-serif !important;
}

/* contact page Css */

.ftco-section{
  margin: 5rem;

}

.left-cont-box{
background-color: #1e8133 ;
  color: white;
}



.info-wrap .dbox {
  /* width: 100%; */
  color: rgba(255, 255, 255, 0.8);
  /* margin-bottom: 25px; */
  /* margin: auto; */
  margin: 25px;
}
.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.icon svg {
  font-size: 20px;
}


/* button css */

.contact-btn {

  background: linear-gradient(to left, #0f3a18 50%, #0f1385  50%);
  background-size: 200% 100%;
  background-position: right;
  color: white !important;
  font-size: medium;
  border-radius: 4px;
  border: transparent;
  transition: all 1s ease-out;
  text-decoration: none;
  font-weight: 700;
  padding: 15px 15px !important;
 
}

.contact-btn:hover {
  background-position: left;
  color: white !important;
  border: 1px solid white !important;
}
.error-text{
  color: red;
}

.left-side{
  /* width: 25%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
 .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
 .left-side .details{
  margin: 14px;
  text-align: center;
}
 .left-side .details svg{
  font-size: 50px;
  color: #1e8133;
  margin-bottom: 10px;
}
 .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
 .left-side .details .text-one,
 .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
}


@media (max-width: 820px) {
  
.left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .left-side::before{
   display: none;
 }
 
}